import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { Link } from "../../../../components/Link"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Taschen mit Flechtdesign"
      category="Frühlingtrends 2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Typography paragraph={true}>
            Dieses Jahr sind besonders die Handtaschen von Bottega Veneta im Trend. Die Miniversion
            der Jodie Tote mit Intrecciato-Flechtmuster von der Marke Bottega Veneta ist auch
            angesagt. Die Influencerin Caroline Daur trägt die Miniversion der BV Jodie Tote auf
            ihren Weg zu Thom Browne’s Show der Pariser Fashion Week zu einem langen Thom Browne
            Mantel aus Tweed. Taschen mit geflochtener Optik sind IN und können überall getragen
            werden: in der Stadt beim Cafe trinken mit Freundinnen oder nachts bei einer Gala. Eine
            geflochtene{" "}
            <Link href="/abendtaschen/" underline="always">
              Abendtasche
            </Link>{" "}
            von{" "}
            <Link href="/marken/cluty/" underline="always">
              Cluty
            </Link>{" "}
            ist dabei sehr schlicht und modern zugleich. Die{" "}
            <Link href="/handtaschen/" underline="always">
              Handtaschen
            </Link>{" "}
            mit Flechtmuster von{" "}
            <Link href="/marken/abro/" underline="always">
              Abro
            </Link>{" "}
            sind sehr elegant und können zu einem City-Outfit kombiniert werden. Wer helle und
            zugleich weiche Farben mag, der wird die{" "}
            <Link href="/marken/mymo/" underline="always">
              myMo
            </Link>{" "}
            <Link href="/beuteltaschen/" underline="always">
              Beuteltaschen
            </Link>{" "}
            mit Flechtdesign lieben.
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <InstagramEmbed
            url="https://www.instagram.com/p/B9OrFhAoWrQ/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24608095595&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Cluty Abendtasche in braun mit Flechtmuster",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/81d065a03275bd887d969e84b564f68a.jpg",
                    title: "Cluty Abendtasche",
                  },
                ],
                name: "Cluty Abendtasche",
                price: 59.9,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608095593&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Cluty Abendtasche in schwarz mit Flechtmuster",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/3857068043a14d4242814d46a622e99c.jpg",
                    title: "Cluty Abendtasche",
                  },
                ],
                name: "Cluty Abendtasche",
                price: 59.9,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=25585560503&a=667803&m=11590",
                images: [
                  {
                    alt: "Eine Abro Handtasche Elvi in braun mit Flechtmuster",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/d393c8b4f34cafca98596f1eec43117c.jpg",
                    title: "Abro Handtasche Elvi braun",
                  },
                ],
                name: "Abro Handtasche Elvi braun",
                price: 399,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25323060271&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Abro Hobo-Bag in schwarz mit Flechtdesign",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/eddd2a18945e2df6a32b1509108a49ea.jpg",
                    title: "Abro Hobo-Bag schwarz",
                  },
                ],
                name: "Abro Hobo-Bag schwarz",
                price: 399,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25873962389&a=667803&m=11926",
                images: [
                  {
                    alt:
                      "Ein Bild einer Liebeskind Berlin Handtasche Santa Fe Shopper XL Medium Brown",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/c9577619309d42845480052ddddddac7.jpg",
                    title: "Liebeskind Berlin Handtasche Santa Fe Shopper XL Medium Brown",
                  },
                ],
                name: "Liebeskind Berlin Handtasche",
                price: 319,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25867915875&a=667803&m=14962",
                images: [
                  {
                    alt: "Ein Bild einer The Bridge Handtasche 'Ghibellina' 29cm",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/f337feade4552a44e559f035ae5a2890.jpg",
                    title: "The Bridge Handtasche 'Ghibellina' 29cm",
                  },
                ],
                name: "The Bridge Handtasche",
                price: 398,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=25833871645&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer myMo Beuteltasche mit Flechtmuster",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/203b5e45521ad10be67c37f4cafb98b3.jpg",
                    title: "myMo Beuteltasche",
                  },
                ],
                name: "myMo Beuteltasche",
                price: 69.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25851634855&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer myMo Beuteltasche mit Flechtmuster",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/6d914597ef3c8bb71e963348f6a0024e.jpg",
                    title: "myMo Beuteltasche",
                  },
                ],
                name: "myMo Beuteltasche",
                price: 69.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25431231855&a=667803&m=14336",
                images: [
                  {
                    alt:
                      "Ein Bild einer Campomaggi Schultertasche aus Leder mit Flechtmuster 35 cm",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/4bb3dc9118ad2e031a83a4f15d6fb647.jpg",
                    title: "Campomaggi Schultertasche Leder 35 cm",
                  },
                ],
                name: "Campomaggi Schultertasche Leder 35 cm",
                price: 540,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25431231881&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Campomaggi Schultertasche Leder mit Flechtdesign 35 cm",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/36d637ba6806d3643d0c3a09f64fbd5c.jpg",
                    title: "Campomaggi Schultertasche Leder 35 cm",
                  },
                ],
                name: "Campomaggi Schultertasche Leder 35 cm",
                price: 540,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
